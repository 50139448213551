





































































































































































































































import { computed, defineComponent, reactive, toRefs } from '@vue/composition-api'
import InviteSnsChannels from '@/components/InviteSnsChannels.vue'
import AskQuestions from '@/components/AskQuestions.vue'
import BottomNotice from '@/components/BottomNotice.vue'
import InviteVisual from '@/components/InviteVisual.vue'
import StampPlate from '@/components/utils/StampPlate.vue'
import store from '@/store'
import { inviteCredits, toReadableNumber } from '@/helpers'
import { useI18n } from 'vue-composable'
import router from '@/router'

interface State {
  [key: string]: any;
}

export default defineComponent({
  components: {
    InviteSnsChannels,
    InviteVisual,
    AskQuestions,
    BottomNotice,
    StampPlate
  },
  setup () {
    const { $t } = useI18n()

    const { $route } = router.app

    const {
      state: {
        auth
      },
      getters
    } = store

    const benefitedCoupons = computed(() => {
      const benefits = auth.invites.benefited_coupon
      if (!benefits || !benefits.length) return [0]
      const coupons = (benefits as string[]).map(coupon => {
        const [amount, currencyIso] = coupon.split(' ')
        return `${toReadableNumber(+amount)} ${currencyIso}`
      })
      return coupons
    })

    const state = reactive({
      $t,
      auth,
      benefitedCoupons,
      helps: computed(() => [
        {
          title: $t('Benefit.question.InviteFriends.1').value,
          content: [$t('Benefit.answer.InviteFriends.1', { amount: toReadableNumber(inviteCredits(state.sourceCountryIso)), currency: getters['country/$currentSourceCurrencyIso'] }).value]
        },
        {
          title: $t('Benefit.question.InviteFriends.2').value,
          content: [$t('Benefit.answer.InviteFriends.2').value]
        },
        {
          title: $t('Benefit.question.InviteFriends.3').value,
          content: [$t('Benefit.answer.InviteFriends.3').value]
        }
      ]),
      notice: {
        title: $t('Event.footer.termsContents.title_foryou').value,
        text: $t('Benefit.paragraph.TermsContent.InviteFreinds').value
      },
      howToInvites: computed(() => [
        {
          answer: $t('Benefit.paragraph.HowInvite.2').value,
          resource: getResource(1)
        },
        {
          answer: $t('Benefit.paragraph.HowInvite.3').value,
          resource: getResource(2)
        }
      ]),
      inviteCredits,
      sourceCountryIso: getters['country/$currentSourceCountryCode'],
      sourceCurrencyIso: getters['country/$currentSourceCurrencyIso'],
      toReadableNumber,
      thumb: computed(() => {
        try {
          return require(`@/assets/images/thumb/invite-${state.sourceCountryIso.toLowerCase()}.svg`)
        } catch {
          return require('@/assets/images/thumb/invite-kr.svg')
        }
      }),
      hasInviteeCount: computed(() => !!auth.invites.unverified_invitee_count || !!auth.invites.invitee_count),
      locale: computed(() => $route.params.lang || 'en')
    }) as State

    const getResource = (index: number) => {
      try {
        return require(`@/assets/images/invite-guide/${state.locale}${index}.svg`)
      } catch {
        return require(`@/assets/images/invite-guide/en${index}.svg`)
      }
    }

    return {
      ...toRefs(state)
    }
  }
})
