














import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
  },
  props: {
    notice: {
      type: Object
    }
  }
})
