





































import Vue from 'vue'
import { defineComponent } from '@vue/composition-api'

const vm: any = new Vue()

export default defineComponent({
  props: {
    title: {
      type: String,
      default: vm.$i18n('Help.title.faq.blank').value
    },
    questions: {
      type: Array,
      default: () => []
    }
  }
})
