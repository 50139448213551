






import { defineComponent } from '@vue/composition-api'
import InviteDetail from '@/components/InviteDetail.vue'
import { sendToApp } from '@/helpers'
import axios from 'axios'
import store from '@/store'
import { IS_LOGGING } from '@/data/constants'

export default defineComponent({
  components: {
    InviteDetail
  },
  setup () {
    const {
      state: {
        auth
      }
    } = store

    const getInvites = async () => {
      const { data: value } = await axios.post('/api/invite/page', { pid: store.getters['auth/$getPid'] })
      store.commit('$SET_STATE', { path: 'auth.invites', value })
      useAmplitudeLogging()
    }

    const getAmount = (currency: string) => {
      const coupons = auth.invites.benefited_coupon.filter((v: string) => v.indexOf(currency) > 0)
      return coupons.length ? Number(coupons[0].split(' ')[0]) : null
    }

    const useAmplitudeLogging = () => {
      const amplitudeProperties = {
        friend_invited: auth.invites.unverified_invitee_count || null,
        friend_invitedVerified: auth.invites.invitee_count || null,
        friend_couponReceived: auth.invites.coupon_sum || null,
        friend_amountReceivedKrw: getAmount('KRW'),
        friend_amountReceivedSgd: getAmount('SGD'),
        friend_amountReceivedIdr: getAmount('IDR'),
        friend_amountReceivedUsd: getAmount('USD'),
        friend_amountReceivedVnd: getAmount('VND')
      }
      Object.keys(amplitudeProperties).forEach(o => {
        amplitudeProperties[o as keyof typeof amplitudeProperties] += ''
      })
      sendToApp('view_myFriends', amplitudeProperties, IS_LOGGING)
    }

    getInvites()

    return {}
  }
})
